import Airtable from "airtable";

export function getGuidebook(deckName) {
  return new Promise((resolve, reject) => {
    const API_KEY = process.env.VUE_APP_AIRTABLE_TOKEN;
    Airtable.configure({
      endpointUrl: "https://api.airtable.com",
      apiKey: API_KEY,
    });
    var base = Airtable.base("appn0WDLC3h9ohCW3");
    var guidebook = {};
    var guidebookFound = false;
    deckName = deckName.toLowerCase(); //Scrubbing data just in case it was capitalized
    // console.log(`GUIDEBOOK REQUESTED: ${deckName}`);
    base("Guidebook")
      .select({
        // maxRecords: 100,
        fields: ["Deck Name", "Guidebook Text"],
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.
          records.forEach(function (record) {
            if (!guidebookFound) {
              //Set the guidebook data to 'Original' by default
              if (record.get("Deck Name").toLowerCase() === "original") {
                guidebook.id = record.getId();
                guidebook.deckName = record.get("Deck Name");
                guidebook.text = record.get("Guidebook Text");
                console.log(`GUIDEBOOK DEFAULT: ${guidebook.deckName}`);
                //Check if the requested guidebook is indeed "Original",
                //so we can stop searching for other matches
                if (deckName === "original") {
                  guidebookFound = true;
                  console.log(`GUIDEBOOK LOADED: ${guidebook.deckName}`);
                  return;
                }
              } else if (record.get("Deck Name").toLowerCase() === deckName) {
                //If the current record matches the target deck, overwrite guidebook data,
                //then mark guidebook as found
                guidebookFound = true;
                guidebook.id = record.getId();
                guidebook.deckName = record.get("Deck Name");
                guidebook.text = record.get("Guidebook Text");
                console.log(`GUIDEBOOK LOADED: ${guidebook.deckName}`);
              }
            }
          });
          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            reject(err);
          } else {
            resolve(guidebook);
          }
        }
      );
  });
}
