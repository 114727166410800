<template>
  <div
    class="card-wrapper"
    :style="{
      width: cardWidthPx,
      height: cardHeightPx,
      top: card.top,
      left: card.left,
      'z-index': card.z,
    }"
    :key="card.key"
    :id="card.id"
    :ref="card.id"
  >
    <div class="card-inner" :class="card.revealed ? 'revealed' : ''">
      <!-- CARD INFOSIDE -->
      <div
        class="card card-infoside"
        :style="{
          'background-color': card.color1,
          color: card.color2,
        }"
      >
        <!-- <div
          v-if="card.svgURL"
          class="card-icon"
          :style="{
            'background-image': 'url(' + card.svgURL + ')',
          }"
        ></div> -->
        <div class="card-header" :style="{ 'background-color': card.color2 }">
          <h1 :style="{ color: card.color1 }">
            {{ card.category }}
          </h1>
        </div>
        <div class="card-text-box">
          <h5>{{ card.text }}</h5>
        </div>
        <div class="card-controls">
          <div
            class="card-delete-btn is-role-card"
            :class="isRoleCard"
            @mousedown="$emit('callDiscard')"
          >
            discard
          </div>
        </div>
      </div>
      <!-- CARD BACKSIDE -->
      <div
        class="card card-backside"
        :style="{
          'background-color': card.color1,
          color: card.color2,
        }"
      >
        <!-- CARD BACKSIDE FOOTER (Column Reverse) -->
        <div class="card-footer" :style="{ 'background-color': card.color2 }">
          <h1 :style="{ color: card.color1 }">
            {{ card.category }}
          </h1>
        </div>

        <!-- FALLBACK SVG START ---------->
        <div v-if="!card.svgRaw" class="svg-container">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            viewBox="0 0 123 122"
            xml:space="preserve"
            :style="{
              fill: card.color2,
            }"
          >
            <path
              class="st0"
              d="M64.6.7c1.5.2 3 .3 4.5.6 10.6 1.4 20.7 5.6 29.1 12.2 12.3 9.5 19.5 22.1 22.3 37.3 1.2 6.6 1.2 13.3.1 19.9-.6 3.5-1.4 6.9-2.6 10.2-.2.6-.1.8.4 1 1.3.5 2.7 1 4.2 1.5-4.1 4-8.8 7.3-14 9.8.4-5.2-.1-10.3-1.3-15.4 1.4.5 2.7.9 3.8 1.4.9.4 1.2.1 1.4-.7 1.2-3.5 2-7.1 2.5-10.7 1.4-10.4-.4-21.1-5.1-30.5-7.4-15.1-19.5-24.6-35.6-29-4.5-1.2-9.2-1.8-13.8-1.7-4.1.1-8.2.6-12.1 1.6-.9.2-1.3.1-1.5-.9-.2-1.3-.6-2.6-1-3.9-.2-.6-.1-.8.6-.9 3.6-.9 7.3-1.4 11-1.7.3 0 .7.1 1-.2h6.1zM.4 37C3 34.6 5.9 32.5 9 30.7c1.6-1 3.4-1.8 5-2.7.5-.3.7-.3.6.4-.3 4.1-.1 8.3.5 12.4.1.7.3 1.4.4 2.2-1.4-.6-2.7-1-4-1.6-.6-.3-.8-.2-1 .5-2.8 7.4-4 15.4-3.4 23.3 1 13.3 6.9 25.8 16.5 35.1s22.3 14.7 35.7 15.1c3.4.1 6.7-.1 10-.6.6-.1.8.2.9.7.2 1.5.5 3 .7 4.5.1.5-.1.6-.6.7-4.1.6-8.3.8-12.4.6-8.1-.4-16-2.5-23.3-6.1-7.3-3.6-13.7-8.6-19-14.8C8.3 92 3.5 81.8 1.7 70.8c-1.5-9.4-.8-19 2.1-28 .3-1 .8-2.1 1.2-3.2.2-.5.2-.8-.4-1-1.5-.5-2.8-1-4.2-1.6z"
            />
            <path
              class="st0"
              d="M100 55.6v-4.1H79.4v4.1l8 1.3c-1.7 7.4-4.5 14.5-8.5 21L78 77c3.4-5.9 5.9-12.4 7.4-19l-8-1.3v-4.1h-3v4.1l8 1.3c-1.3 5.9-3.4 11.7-6.2 17-2.9-3.2-5.8-6.2-9-9.7-3.5-3.8-6-6.6-8.2-9 10.5-5.6 15.2-10.5 15.2-17.6 0-7.7-5.5-13-15.5-13-2.5 0-4.8.4-6.8 1.1C43.2 27.2 37 32.6 37 41c0 5.4 1.9 10.6 7 16.4.2.2.3.4.5.5-10.2 5-15.5 11.9-15.5 21 0 10.3 8.8 18.6 21.5 18.6.6 0 1.2 0 1.7-.1-12-.5-20.2-8.5-20.2-18.5 0-9.1 5.3-16 15.4-21.1l-.5-.5c-5.1-5.8-7-11-7-16.4 0-6.2 3.4-10.7 8.7-12.9-4 2.5-6.6 6.6-6.6 12s1.9 10.6 7 16.4c.2.2.3.4.5.5-10.2 5-15.5 11.9-15.5 21 0 10.3 8.8 18.6 21.5 18.6 7.6 0 13.3-2.3 17.5-5.7.6.6 1.2 1.3 1.8 2 3.4 3.5 6.6 4.7 11.9 4.7.5 0 .9 0 1.4-.1-4.4-.2-7.2-1.6-10.3-4.7-1.1-1.2-2.1-2.3-3.1-3.4l1.5-1.5c1.1 1.3 2.3 2.6 3.6 4 3.4 3.5 6.6 4.7 11.9 4.7 2.6 0 5.2-.4 7.8-1l.4-4.4-9.9-1.4c-3.1-3.3-5.8-6.2-8.4-8.9 4.9-7.3 8.4-15.4 10.5-23.9l7.9-1.3zM63 72.9c3.4 3.8 6.7 7.5 10.4 11.7-1.6 1.3-3.4 2.4-5.4 3.3 1.2-.7 2.3-1.5 3.3-2.3-3.7-4.1-7-7.9-10.4-11.7-4.6-5.4-7.6-9.1-10.9-12.9l1.8-1.2c3.4 3.8 6.5 7.5 11.2 13.1zM49.4 38.6c0-1.2.2-2.5.6-3.6.4-1.1 1.1-2.2 2-3.1.9-.9 1.9-1.6 3-2.1s2.4-.7 3.6-.7c5 0 8.8 3.6 8.8 9.5S63 49.1 56.6 53.4c-.1-.1-.2-.2-.2-.3 5.4-4 9-8.2 9-13.6 0-5.9-3.8-9.5-8.8-9.5-.5 0-1 0-1.5.1 4.3.7 7.3 4.1 7.3 9.4 0 4.7-2.8 8.5-7.2 12.1-.4-.5-.9-1.1-1.4-1.7-3.6-4.6-4.4-7.7-4.4-11.3zm-6.6 36.6c0-4.1 1.3-8.8 5.5-12.7 2.8 3.3 5.6 6.7 9.6 11.5 3.4 3.8 6.7 7.5 10.4 11.7-2.2 1.9-4.8 3.4-7.8 4.3-.7-.1-1.5 0-2.3 0-9.8 0-15.4-7.4-15.4-14.8z"
            />
            <path
              class="st1"
              d="M92.4 86.4c-.1 0-.2-.1-.2-.1l-1.7-2.1c-.3-.3-.2-.3-.6-.1l-2.4 1-.2.1h-.1v-.1l.5-.4c.6-.5 1.1-.9 1.7-1.4l.1-.1.1-.1v-.2c0-.1 0-.1-.1-.1-.4-.9-.7-1.7-1.1-2.6 0-.1-.1-.2 0-.2s.1.1.1.1l1.7 2.1c.2.3.2.3.6.2l2.5-1c.1 0 .2-.1.2 0s-.1.1-.1.2l-2.1 1.7c-.3.2-.3.2-.1.6.3.8.7 1.7 1 2.5.1-.1.2 0 .2 0M27.8 50.5c.6.6 1.1 1.3 1.6 2s.4.6 1.1.3l2.2-.9c.1 0 .2-.1.2-.1 0 .1-.1.1-.1.2l-2.2 1.7c-.3.2-.3.2-.2.6.4.9.7 1.7 1.1 2.6 0 0 .1.1 0 .2-.1 0-.1-.1-.1-.1-.6-.8-1.2-1.5-1.8-2.2l-.1-.1-.1-.1h-.1s-.1 0-.1.1c-.8.4-1.7.7-2.5 1-.1 0-.2.1-.2.1 0-.1.1-.1.1-.2.7-.6 1.4-1.2 2.2-1.7l.1-.1s0-.1.1-.1v-.1-.1c-.4-.9-.7-1.7-1.1-2.6-.1-.3-.1-.3-.1-.4M84 29.6c-.1.2-.3.3-.5.5-1.1.9-2.1 1.7-3.2 2.5-.2.2-.2.3-.1.6.5 1.3 1.1 2.6 1.6 3.8 0 .2.3.4.1.5-.2.1-.2-.2-.4-.4-.9-1.1-1.7-2.1-2.5-3.2-.2-.3-.4-.3-.7-.2-1.3.5-2.7 1.1-4 1.6-.1 0-.2.1-.2 0-.1-.1.1-.1.1-.2l3.3-2.6c.5-.4.5-.4.3-.9-.4-1.1-.9-2.2-1.3-3.3-.2-.3-.2-.6-.2-.9 1 1.2 1.9 2.3 2.9 3.5.4.4.4.4.9.2l3.8-1.5h.1z"
            />
          </svg>
          <!-- FALLBACK SVG END  ----------->
        </div>
        <!-- SVG BEGIN ----------->
        <div v-if="card.svgRaw" class="svg-container">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            viewBox="0 0 123 122"
            xml:space="preserve"
            :style="{
              fill: card.color2,
            }"
            v-html="card.svgRaw"
          ></svg>
        </div>
        <!-- SVG END ------------->
        <div class="card-controls-front">
          <div class="hint-text">double-click to flip</div>
          <div
            class="card-delete-btn-round is-role-card"
            :class="isRoleCard"
            @mousedown="$emit('callDiscard')"
          >
            ✕
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    card: {
      id: String,
      revealed: Boolean,
      name: String,
      svgURL: String,
      svgRaw: String,
      color1: String,
      color2: String,
      category: String,
      text: String,
      left: String,
      top: String,
      z: String,
    },
    cardWidthPx: String,
    cardHeightPx: String,
  },
  emits: ["callDiscard"],
  data() {
    return {};
  },
  computed: {
    isRoleCard: function () {
      if (this.category === "Role") {
        return "is-role-card";
      } else {
        return "";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-wrapper {
  position: absolute;
  background-color: transparent;
  /* Fallback only; Card dimensions & margins are set dynamically in Data() */
  width: 120px;
  height: 170px;
  /* For 3D effect */
  perspective: 1000px;
}
.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.card-inner.revealed {
  transform: rotateY(180deg);
}

.card {
  cursor: grab;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  margin: 0;
  background-color: pink;
  background-color: var(--var-IIF-teal);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.3);
}
.card-infoside {
  transform: rotateY(180deg);
  text-transform: uppercase;
  font-size: 1.9rem;
}
.card-backside {
  /* fallback background color */
  background-color: var(--var-IIF-teal);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 85%;
  background-position-y: 25%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  transform: rotateX(0deg);
}
.svg-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}
.card-backside svg {
  width: 80%;
  height: 80%;
  padding: 1rem;
  fill: rgba(255, 255, 255, 0.4);
}
.card-footer {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 20%;
  margin-top: 0;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  /* fallback background color */
  background-color: var(--var-IIF-darker-teal);
  color: var(--var-IIF-teal);
}

.card-infoside {
  /* fallback font color */
  color: var(--var-IIF-darker-teal);
}
.card-infoside .card-icon {
  position: absolute;
  left: 0;
  top: 10%;
  height: 100%;
  width: 100%;
  opacity: 0.1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 85%;
}

.card-infoside .card-header {
  box-sizing: border-box;
  display: flex;
  height: 25%;
  margin-top: 0;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: var(--var-IIF-darker-teal);
  color: var(--var-IIF-teal);
}
.card-infoside h1,
.card-backside h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  padding: 0;
}

.card-infoside .card-text-box {
  box-sizing: border-box;
  position: relative;
  display: flex;
  height: 75%;
  margin-top: 0;
  padding: 0 0.5rem;
  align-items: center;
  justify-content: center;
}
.card-infoside h5 {
  display: inline-block;
  font-size: 1.9rem;
  text-align: center;
  padding-bottom: 1rem;
}
.card-controls {
  position: fixed;
  opacity: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
}
.card-infoside:hover > .card-controls {
  opacity: 1;
}

.card-controls-front {
  opacity: 0;
  display: flex;
  width: 100%;
  padding: 0 5px 0 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.3s;
}
.card-backside:hover > .card-controls-front {
  opacity: 1;
}
.hint-text {
  color: white;
  font-family: var(--var-IIF-sans-body);
  font-size: 0.9rem;
  opacity: 0.7;
  text-transform: uppercase;
}
.hint-text:hover {
  opacity: 1;
}

.card-delete-btn {
  cursor: pointer;
  padding: 2px 10px;
  color: rgba(255, 255, 255, 0.35);
  font-family: var(--var-IIF-sans-body);
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 1rem;
  transition: background-color 0.3s;
}
.card-delete-btn-round {
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.45);
  font-family: var(--var-IIF-sans-body);
  font-size: 1.5rem;
  text-transform: uppercase;
  border-radius: 1rem;
  transition: background-color 0.3s;
}

.card-delete-btn.is-role-card:hover,
.card-delete-btn:hover,
.card-delete-btn-round:hover {
  background-color: #e03f3f;
  color: white;
}

.card-delete-btn.is-role-card {
  background-color: rgba(0, 0, 0, 0.15);
  color: white;
}
</style>
