<template>
  <div class="main">
    <div class="container">
      <div class="mobile-notice">
        <div class="hero-text">More&More</div>
        <div
          class="hero-image"
          alt="Image of a deck of Investing in Futures cards in their box, and a hand holding fanned out cards to it's right."
        ></div>
        <h1>We're sorry</h1>
        <h2>Mobile and small screens are currently not supported.</h2>
        <p>Please visit this site on a larger screen.</p>
        <a href="https://moreandmore.world/About/" target="_blank">
          <div class="more-info-btn">About the Project</div>
        </a>
      </div>
      <!-- TOOLTIPS -->
      <!-- TOOLTIP #1 -------------->
      <div
        v-if="onBoardingStage === 0"
        class="tooltip"
        :style="currentTooltipPosStyle"
      >
        <div class="tooltip-text">
          <h1>More&More</h1>
          <h3>Imagine a world you want to live in</h3>
          <div class="suggested-tooltip">
            <div class="suggest-tooltip-header">
              <div class="tooltip-ziggy-arrow-right" role="resentation" />
            </div>
            <div class="suggested-tooltip-content">
              Select cards from different categories. Use these as a starting
              point for your world.
            </div>
          </div>
          <div class="suggested-tooltip">
            <div class="suggest-tooltip-header lighter">
              <div class="tooltip-ziggy-arrow-left" role="resentation" />
            </div>
            <div class="suggested-tooltip-content">
              Consult the guidebook for more suggestions.
            </div>
          </div>
        </div>
        <div class="tooltip-controls" style="justify-content: center">
          <!-- <div class="button back">Back</div> -->
          <div class="button" @mousedown="skipOnBoarding()">Ok, Start!</div>
        </div>
      </div>
      <!-- TOOLTIP #2's card illustrations -->
      <!-- <div v-if="onBoardingStage === 1" class="tooltip2-illustrations">
        <div class="illustration-card card-1"></div>
        <div class="illustration-card card-2"></div>
        <div class="illustration-card card-3"></div>
        <div id="card-4-wrapper">
          <div class="card-4-inner revealed">
            <div class="card-4-backside"></div>
            <div class="card-4-frontside"></div>
          </div>
        </div>
        <div class="illustration-card card-5"></div>
        <div class="illustration-card card-6"></div>
      </div> -->
      <!-- TOOLTIP #2 -------------->
      <div
        v-if="onBoardingStage === 1"
        class="tooltip"
        :style="currentTooltipPosStyle"
      >
        <div class="tooltip-text">
          <div class="ziggy-box column">
            <div class="double-click" role="presentation" />
          </div>
          <h1>Double click cards to flip over</h1>
          <h2>You can click + drag to move the cards around</h2>
        </div>
        <div class="tooltip-controls">
          <div class="button back" @mousedown="goToPrevOnBoardingStage()">
            Back
          </div>
          <div class="button" @mousedown="goToNextOnBoardingStage()">Next</div>
        </div>
        <div class="skip-on-boarding" @mousedown="skipOnBoarding()">
          Skip On-Boarding
        </div>
      </div>
      <!-- TOOLTIP #3 -------------->
      <div
        v-if="onBoardingStage === 2"
        class="tooltip flex-row"
        :style="currentTooltipPosStyle"
      >
        <div class="tooltip-ziggy-arrow-down" role="presentation" />
        <div>
          <div class="tooltip-text">
            <div>
              <h1>For instructions, consult the guidebook</h1>
              <h2>or feel free to create your own way to play</h2>
            </div>
          </div>
          <div class="tooltip-controls">
            <div class="button back" @mousedown="goToPrevOnBoardingStage()">
              Back
            </div>
            <div class="button" @mousedown="goToNextOnBoardingStage()">
              Ok, Start!
            </div>
          </div>
        </div>
      </div>

      <!-- CARD TABLE -->
      <div class="card-table">
        <div class="card-row">
          <!-- CARDS DIV -->
          <Card
            v-for="(card, index) in dealtCards"
            :key="card.id"
            :card="card"
            :cardWidthPx="cardWidthPx"
            :cardHeightPx="cardHeightPx"
            @mousedown="dragMouseDown($event, card.id, index)"
            @dblclick="revealCard($event, index)"
            @contextmenu="openCardContextMenu()"
            v-on:callDiscard="discard($event, card.id, index)"
          ></Card>
        </div>
      </div>
      <!-- SIDEBAR -->
      <div class="sidebar">
        <!-- CONSTRAINTS SECTION -->
        <div class="constraints-div">
          <div class="constraints-div-header">Categories</div>
          <div
            class="deal-btn"
            v-for="(cat, id) in constraintCategories"
            :key="id"
          >
            <div
              class="deal-ico"
              @click="dealCard(id)"
              :style="{
                'background-color': cat.color1,
                'border-top-color': cat.color2,
              }"
            ></div>
            <div class="category-label" @click="dealCard(id)">
              {{ cat.name }}
              <!-- ,{{ cat.cardsRemaining }} -->
            </div>
          </div>
        </div>
        <!-- ROLE(S) SECTION -->
        <div class="identity-div">
          <div class="deal-btn" v-for="(cat, id) in roleCategories" :key="id">
            <div
              class="deal-ico"
              @mousedown="dealCard(id)"
              :style="{
                'background-color': cat.color1,
                'border-top-color': cat.color2,
              }"
            ></div>

            <div class="category-label" @mousedown="dealCard(id)">
              {{ cat.name }}
              <!-- ,{{ cat.cardsRemaining }} -->
            </div>
          </div>
        </div>
        <!-- DISCARDED SECTION -->
        <div class="discarded-div" :class="{ disabled: noDiscardedCards }">
          <div class="deal-btn">
            <img
              class="discard-ico"
              src="../assets/Trashcan.png"
              @click="undoDiscard()"
            />
            <div class="category-label" @click="undoDiscard()">
              Restore Discarded Cards
            </div>
          </div>
        </div>
        <div class="reset-game-div">
          <!-- RESET ALL BUTTON -->
          <div id="reset-all-btn" @mousedown="showConfirmResetModal()">
            Shuffle &amp; Reset
          </div>
        </div>
      </div>
      <!-- GUIDE BOOK -->
      <div v-if="guideOpen" id="guide-book" class="guide-book">
        <div class="header" @mousedown="guidebookDragMouseDown($event)">
          <div class="guidebook-move-header">
            <img
              class="move-handle"
              src="../assets/guide-book-move-handle.png"
            />
            <h1>GUIDE BOOK</h1>
          </div>
          <div
            class="close-btn"
            @mousedown="hideGuideBook()"
            alt="close guidebook panel"
          ></div>
        </div>
        <div class="content">
          <!-- BEGIN HOLDER GUIDE BOOK -->
          <vue3-markdown-it
            class="markdown-guidebook"
            :source="guidebook.text"
            :key="guidebook.id"
          />
          <!-- END HOLDER GUIDE BOOK -->
        </div>
      </div>
      <div v-if="!guideOpen" id="guide-book-btn" @mousedown="showGuideBook()">
        Open Guide Book
      </div>
      <!-- RESET ALL CONFIRMATION MODAL -->
      <div
        v-if="resetModalOpen"
        id="modal-screen-dimmer"
        @mousedown="hideConfirmResetModal()"
      ></div>
      <div v-if="resetModalOpen" id="exit-confirmation-modal">
        <div class="modal-header">
          <div class="exit-modal-title">
            <div class="exit-modal-title-ico"></div>
            Reset &amp; Shuffle
          </div>
          <div
            class="close-btn"
            @mousedown="hideConfirmResetModal()"
            alt="close reset dialogue"
          ></div>
        </div>
        <div class="exit-modal-text">
          <h1>Do you want to proceed?</h1>
          <h2>This will clear all existing cards and shuffle the deck.</h2>
        </div>
        <div class="btn-container">
          <button class="cancel-btn" @mousedown="hideConfirmResetModal()">
            Cancel
          </button>
          <button class="confirm-btn" @mousedown="resetGame()">
            Yes, Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { getDeck } from "@/services/CardsService.js";
import { getGuidebook } from "@/services/GuidebookService.js";
import Card from "@/components/Card.vue";

export default {
  name: "Game Main",
  data() {
    return {
      deckId: this.$route.params.deckId,
      gameStarted: false,
      guideOpen: true,
      guidebook: {
        //id: Number,
        //text: String,
      },
      resetModalOpen: false,
      onBoardingStage: 0,
      tooltips: [
        // // On-Boarding Info for Tooltips
        // {
        //   text: String,
        //   posX: Number,
        //   posY: Number,
        // },
        {
          text:
            "Choose six cards, each from a different category. Each card describes an aspect of an alternate world for you to imagine.",
          posX: "",
          posY: "",
        },
        {
          text:
            "Click cards to deal. Double-click to flip over. You can click + drag to move the cards around",
          posX: "30vw",
          posY: "40vh",
        },
        {
          text:
            "For detailed play instructions, consult the guidebook (or create your own way to play!)",
          posX: "15vw",
          posY: "23vh",
          tooltipWidth: "60rem",
        },
      ],
      deck: [
        {
          // Template of a Category
          id: String,
          name: String,
          color1: String,
          color2: String,
          cards: [
            // Template of a Card
            {
              id: String,
              text: String,
              dealt: Boolean,
            },
          ],
        },
      ],
      roleCategories: {},
      constraintCategories: {},
      cardsPerRow: null,
      maxRows: null,
      maxCardsOnTable: null,
      currentRow: null,
      cardsGridLayer: null,
      dealtCards: [
        // {
        //   id: String,
        //   revealed: Boolean,
        //   name: String,
        //   svgURL: String,
        //   svgRaw: String,
        //   color1: String,
        //   color2: String,
        //   category: String,
        //   text: String,
        //   left: String,
        //   top: String,
        //   z : String,
        // },
      ],
      numDealtCards: 0,
      discardedCards: [],
      // Values for positioning cards
      cardHeight: 220,
      cardWidth: 150,
      cardMargins: 10,
      gameTableMargins: 10,
      navbarHeight: 45, //4.5rem
      guidebookWidth: 430, //40rem + 3rem padding
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
        targetId: undefined,
        targetIndex: 0,
        topZ: 100,
      },
    };
  },
  components: {
    Card,
  },
  methods: {
    // ON-BOARDING FUNCTIONS --------------
    goToNextOnBoardingStage() {
      if (this.onBoardingStage === 0) {
        ++this.onBoardingStage;
        // setTimeout(function () {
        //   document.getElementById("card-4-wrapper").classList.add("revealed");
        // }, 1000);
      } else if (this.onBoardingStage === 1) {
        ++this.onBoardingStage;
        this.guideOpen = true;
      } else if (this.onBoardingStage === 2) {
        this.onBoardingStage = undefined;
      } else {
        this.onBoardingStage = undefined;
        console.log("On-Boarding DONE");
      }
    },
    goToPrevOnBoardingStage() {
      if (this.onBoardingStage <= 2 && this.onBoardingStage >= 1) {
        --this.onBoardingStage;
      }
    },
    skipOnBoarding() {
      this.guideOpen = true;
      this.onBoardingStage = undefined;
    },
    fetchNewDeck() {
      getDeck(this.deckId)
        .then((theData) => {
          this.setupCategoriesAndRoles(theData);
          //this.deck = theData;
          // console.log("Data keys: ", Object.keys(this.deck));
          // console.log(this.deck);
        })
        .catch((err) => {
          console.log("Oh noes, something went wrong: ", err);
        });
    },
    setupCategoriesAndRoles(theRawData) {
      console.log("setting up categories and roles");
      this.deck = theRawData;
      let keys = Object.keys(this.deck);
      for (var i = 0; i < keys.length; i++) {
        let currentKey = keys[i];
        if (this.deck[currentKey].name === "Role") {
          this.roleCategories[currentKey] = this.deck[currentKey];
        } else {
          this.constraintCategories[currentKey] = this.deck[currentKey];
        }
      }
    },
    setupDealtCardCounts() {
      console.log("setupDealtCardCounts() called.");
      let keys = Object.keys(this.deck);
      for (var i = 0; i < keys.length; i++) {
        let currentKey = keys[i];
        this.deck[currentKey].cardsDealt = 0;
        this.deck[currentKey].cardsRemaining = this.deck[
          currentKey
        ].cards.length;
        // console.log(
        //   "Cards dealt in",
        //   this.deck[currentKey].name,
        //   ": ",
        //   this.deck[currentKey].cardsDealt,
        //   " of ",
        //   this.deck[currentKey].cardsRemaining
        // );
      }
    },
    dealCard(catId) {
      // if card counts have not been set up, do it now
      if (this.deck[catId].cardsDealt == undefined) {
        //mark game as started
        this.gameStarted = true;
        this.setupDealtCardCounts();
      }
      //Check if there's anymore cards to deal
      if (this.deck[catId].cardsRemaining <= 0) {
        alert("No more cards to deal in this category.");
      } else {
        //1. pick a card at random
        const random = Math.floor(
          Math.random() * this.deck[catId].cardsRemaining
        );
        //2. create copy and add to global dealtCard array
        this.numDealtCards = this.numDealtCards + 1.0;
        // console.log("Incrementing numdealtCards: " + this.numDealtCards);
        let thisCard = {};
        thisCard.revealed = false;
        thisCard.category = this.deck[catId].name;
        thisCard.svgURL = this.deck[catId].svgURL;
        thisCard.svgRaw = this.deck[catId].svgRaw;
        thisCard.color1 = this.deck[catId].color1;
        thisCard.color2 = this.deck[catId].color2;
        thisCard.text = this.deck[catId].cards[random].text;
        thisCard.id = this.deck[catId].cards[random].id;
        // Place cards on table
        let cardPos = this.placeCard(this.numDealtCards);
        thisCard.left = cardPos.x + "px";
        thisCard.top = cardPos.y + "px";
        // Push this card to dealtCards array
        this.dealtCards.push(thisCard);
        // console.log(this.dealtCards);
        //2. mark as dealt and move it to the back of the array
        this.deck[catId].cards[random].dealt = true;
        this.deck[catId].cards[random].dealOrder = this.deck[catId].cardsDealt;
        // console.log(
        //   'Category: "',
        //   this.deck[catId].name,
        //   '" Text: "',
        //   this.deck[catId].cards[random].text,
        //   '", dealt: ',
        //   this.deck[catId].cards[random].dealt,
        //   ", dealOrder: ",
        //   this.deck[catId].cards[random].dealOrder
        // );
        this.deck[catId].cardsDealt++;
        this.deck[catId].cardsRemaining--;
        let chosenCard = this.deck[catId].cards.splice(random, 1)[0];
        this.deck[catId].cards.push(chosenCard);
      }
    },
    placeCard: function (dealtCardNumber) {
      // Card dimensions and margins are set in Data():
      // cardHeight: __,
      // cardWidth: __,
      // cardMargins: __,
      // gameTableMargins: __,
      let gameTableWidth = window.innerWidth * 0.8;
      let gameTableHeight = window.innerHeight - 45;
      // If cardsPerRow not set, calculate now ----------------
      if (this.cardsPerRow === null) {
        this.cardsPerRow = Math.floor(
          (gameTableWidth - this.guidebookWidth - 2 * this.gameTableMargins) /
            (this.cardWidth + 2 * this.cardMargins)
        );
      }
      // If maxRows not set, calculate now ----------------
      if (this.maxRows === null) {
        this.maxRows = Math.floor(
          (gameTableHeight - 2 * this.gameTableMargins) /
            (this.cardHeight + 2 * this.cardMargins)
        );
      }
      // If maxCardsOnTable not set, calculate now ----------------
      if (this.maxCardsOnTable === null) {
        this.maxCardsOnTable = this.maxRows * this.cardsPerRow;
      }
      //Calculate additional margins needed to center cards ----------------
      let additionalMargin = Math.floor(
        0.5 *
          (gameTableWidth -
            this.guidebookWidth -
            2 * this.gameTableMargins -
            this.cardsPerRow * (this.cardWidth + 2 * this.cardMargins))
      );
      // Calculate card position: which layer? ----------------
      // Initialize cardLayer if not initialized
      if (this.cardsGridLayer === null) {
        this.cardsGridLayer = 0;
      }
      if (dealtCardNumber < this.maxCardsOnTable) {
        this.cardsGridLayer = 0;
      } else if (dealtCardNumber % this.maxCardsOnTable === 0) {
        this.cardsGridLayer =
          Math.floor(dealtCardNumber / this.maxCardsOnTable) - 1;
      } else {
        this.cardsGridLayer = Math.floor(
          dealtCardNumber / this.maxCardsOnTable
        );
      }
      // calculate card position: which row? ----------------
      // Initialize currentRow if not initialized
      if (this.currentRow === null) {
        this.currentRow = 0;
      }

      // TODO: REMOVE THIS CODE CHUNK IF NEW CARD PLACEMENT WORKS
      let rowNumber;
      if (dealtCardNumber <= this.cardsPerRow) {
        rowNumber = 0;
      } else if (dealtCardNumber % this.cardsPerRow === 0) {
        rowNumber = Math.floor(dealtCardNumber / this.cardsPerRow) - 1;
      } else {
        rowNumber = Math.floor(dealtCardNumber / this.cardsPerRow);
      }
      // console.log(
      //   "dealtCardNumber: " + dealtCardNumber + ", rowNumber: " + rowNumber
      // );
      if (rowNumber >= this.maxRows) {
        this.currentRow = rowNumber % this.maxRows;
      } else {
        this.currentRow = rowNumber;
      }
      let yPos =
        this.navbarHeight +
        this.gameTableMargins +
        (this.cardHeight + this.cardMargins) * this.currentRow +
        this.cardsGridLayer * this.cardMargins;

      // calculate card position: which column?
      let colNumber;
      if (dealtCardNumber <= this.cardsPerRow) {
        colNumber = dealtCardNumber - 1;
        // console.log(
        //   "colNumber: " + colNumber + ", this.cardsPerRow: " + this.cardsPerRow
        // );
      } else if (dealtCardNumber % this.cardsPerRow === 0) {
        colNumber = this.cardsPerRow - 1;
      } else {
        colNumber = Math.floor(dealtCardNumber % this.cardsPerRow) - 1;
      }
      let xPos =
        this.gameTableMargins +
        this.guidebookWidth +
        additionalMargin +
        (this.cardWidth + this.cardMargins) * colNumber +
        this.cardsGridLayer * this.cardMargins;
      var cardPos = { x: xPos, y: yPos };
      // console.log("xPos = " + xPos + ", yPos = " + yPos);
      return cardPos;
    },
    revealCard: function (event, cardIndex) {
      event.preventDefault();
      this.dealtCards[cardIndex].revealed = true;
      console.log(
        "Revealing card index: ",
        cardIndex,
        ", ",
        this.dealtCards[cardIndex].text
      );
    },
    // CARD DISCARDING FUNCTIONS  -------------------------
    openCardDiscardMenu: function () {},
    discard: function (event, cardId, cardIndex) {
      // event.preventDefault();
      let cardToDiscard = this.dealtCards[cardIndex];
      this.dealtCards.splice(cardIndex, 1);
      this.discardedCards.push(cardToDiscard);
      console.log("Discarded: ", cardId, " cardIndex:", cardIndex);
    },
    undoDiscard: function () {
      //console.log("discarded cards pile:" + this.discardedCards);
      if (this.discardedCards.length > 0) {
        let cardToRestore = this.discardedCards[this.discardedCards.length - 1];
        this.dealtCards.push(cardToRestore);
        this.discardedCards.pop();
      } else {
        alert("No more cards left to put back!");
      }

      //TODO: Check if there are any more cards to restore
    },
    // GUIDE BOOK FUNCTIONS -------------------------
    fetchGuidebook() {
      getGuidebook(this.deckId)
        .then((theData) => {
          this.guidebook = theData;
        })
        .catch((err) => {
          console.log(
            "Oh noes, something went wrong while fetching the guidebook for deck: ${theData.deckName}: ",
            err
          );
        });
    },
    showGuideBook() {
      this.guideOpen = true;
    },
    hideGuideBook() {
      this.guideOpen = false;
    },
    guidebookDragMouseDown(event) {
      event.preventDefault();
      // get the mous cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.guidebookDrag;
      document.onmouseup = this.closeDragElement;
    },
    guidebookDrag: function (event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      // TODO: Set max drag area to within window limits
      document.getElementById("guide-book").style.position = "absolute";
      document.getElementById("guide-book").style.top =
        document.getElementById("guide-book").offsetTop -
        this.positions.movementY +
        "px";
      document.getElementById("guide-book").style.bottom = "auto";
      document.getElementById("guide-book").style.left =
        document.getElementById("guide-book").offsetLeft -
        this.positions.movementX +
        "px";
      // document.getElementById("guide-book").style.top = 100 + "px";
      // document.getElementById("guide-book").style.left = 100 + "px";
    },
    // CARD DRAGGING FUNCTIONS -------------------------
    dragMouseDown: function (event, id, index) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      this.positions.targetId = id.toString();
      this.positions.targetIndex = index;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      // console.log("elementDrag() called");
      // console.log("targetId:", this.positions.targetId);
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      document.getElementById(this.positions.targetId).style.position =
        "absolute";
      this.dealtCards[this.positions.targetIndex].z = this.positions.topZ;
      this.positions.topZ++;
      this.dealtCards[this.positions.targetIndex].top =
        document.getElementById(this.positions.targetId).offsetTop -
        this.positions.movementY +
        "px";
      this.dealtCards[this.positions.targetIndex].left =
        document.getElementById(this.positions.targetId).offsetLeft -
        this.positions.movementX +
        "px";
    },
    // closeDragElement() is used by both card and guidebook drag functions
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
    // FUNCTIONS FOR RESETTING GAME  -------------------------
    showConfirmResetModal: function () {
      if (this.resetModalOpen === false) {
        this.resetModalOpen = true;
      }
    },
    hideConfirmResetModal: function () {
      if (this.resetModalOpen === true) {
        this.resetModalOpen = false;
      }
    },
    resetGame: function () {
      //If reset modal is open, close it;
      if (this.resetModalOpen) {
        this.resetModalOpen = false;
      }
      //clear dealtCards array
      this.dealtCards = [];
      this.discardedCards = [];
      this.numDealtCards = 0;
      // reset all cards to undealt
      for (let i = this.deck.length; i < this.deck.length; i++) {
        for (
          let j = this.deck[i].cards.length;
          j < this.deck[i].cards.length;
          j++
        ) {
          this.deck[i].card[j].dealt = false;
        }
      }
      console.log("resetGame() called");
    },
    reloadWindow: function () {
      window.location.reload();
    },
  },
  computed: {
    gameNotStarted: function () {
      return !this.gameStarted;
    },
    currentTooltipText() {
      return this.tooltips[this.onBoardingStage].text;
    },
    currentTooltipPosX() {
      return this.tooltips[this.onBoardingStage].posX;
    },
    currentTooltipPosY() {
      return this.tooltips[this.onBoardingStage].posY;
    },
    currentTooltipWidth() {
      return this.tooltips[this.onBoardingStage].tooltipWidth;
    },
    currentTooltipPosStyle() {
      let thisPosStyle =
        "top:" +
        this.currentTooltipPosY +
        "; left:" +
        this.currentTooltipPosX +
        ";";
      if (this.currentTooltipWidth !== undefined) {
        thisPosStyle = thisPosStyle + "; width:" + this.currentTooltipWidth;
      }
      return thisPosStyle;
    },
    currentDeckId() {
      return this.$route.params.deckId;
    },
    cardWidthPx: function () {
      return this.cardWidth + "px";
    },
    cardHeightPx: function () {
      return this.cardHeight + "px";
    },
    noDiscardedCards: function () {
      if (this.discardedCards.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.fetchNewDeck();
    this.fetchGuidebook();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100vh;
  padding-top: 3rem;
  box-sizing: border-box;
  background: #e9e9e9;
  /* background-image: url(../assets/game-table-gradient-bg@2x.jpg); */
  background-size: 400px 400px;
}
/* MOBILE INCOMPATIBILITY NOTICE */
.mobile-notice {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 0 2rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #e9e9e9;
  /* background-image: url(../assets/game-table-gradient-bg@2x.jpg); */
  background-size: 400px 400px;
  z-index: 3000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-notice .hero-text {
  color: var(--var-IIF-blue);
  text-shadow: -0.2rem 0.2rem 0 var(--var-IIF-cyan);
  font-size: 5.8rem;
  font-weight: bolder;
  line-height: 5.4rem;
  letter-spacing: -0.4rem;
  text-transform: uppercase;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 4rem;
}

.mobile-notice .hero-image {
  width: 338px;
  height: 373px;
  background-image: url("../assets/IIF_hand_image-transparent.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.mobile-notice h1,
.mobile-notice h2,
.mobile-notice p {
  color: var(--var-IIF-darker-teal);
  margin: 0 0 1.2rem 0;
  text-align: center;
}
.mobile-notice h1 {
  text-transform: uppercase;
  font-size: 4rem;
}
.mobile-notice h2 {
  font-size: 2.2rem;
}
.mobile-notice p {
  font-size: 1.8rem;
}

.mobile-notice a {
  text-decoration: none;
  .more-info-btn {
    box-sizing: border-box;
    background-color: var(--var-IIF-dark-teal);
    border-radius: 0.5rem;
    font-size: 2rem;
    color: white;
    padding: 1rem 2rem;
    margin: 2rem 1rem;
    border: 3px solid var(--var-IIF-dark-teal);
  }
  .more-info-btn:hover {
    color: var(--var-IIF-dark-teal);
    background-color: white;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .mobile-notice {
    display: none;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* TOOLTIPS ---------- */
.tooltip {
  width: 43rem;
  height: auto;
  background: black;
  color: white;
  box-sizing: border-box;
  padding: 4rem 4rem 3rem 4rem;
  position: absolute;
  border-radius: 8px;
  right: calc(0.5 * (100vw - 43rem));
  top: 10rem;
  font-size: 2rem;
  z-index: 2002;
  box-shadow: 0 0 20px 11px #fff, 0 0 200px 1000px #e9e9e9b5,
    0 0 200px 0 #e9e9e9;
}
.tooltip.top::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #ff6b00 transparent;
}
.tooltip.right::after {
  content: " ";
  position: absolute;
  bottom: 50%;
  left: 100%; /* At the right edge of the tooltip */
  margin-bottom: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #ff6b00;
}
.tooltip.bottom::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #ff9900 transparent transparent transparent;
}
.tooltip.left::after {
  content: " ";
  position: absolute;
  bottom: 50%;
  right: 100%; /* At the right edge of the tooltip */
  margin-bottom: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #ff6b00 transparent transparent;
}

.tooltip.flex-row {
  display: flex;
}

.tooltip-ziggy-arrow-right {
  width: 6.8rem;
  height: 1.2rem;
  background-image: url(~@/assets/ZiggyZig-right.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0.8rem;
}
.tooltip-ziggy-arrow-left {
  width: 6.8rem;
  height: 1.2rem;
  background-image: url(~@/assets/ZiggyZig-left.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0.8rem;
}
.tooltip-ziggy-arrow-up {
  height: 6.8rem;
  width: 1.2rem;
  background-image: url(~@/assets/ZiggyZig-up.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0.8rem;
}
.tooltip-ziggy-arrow-down {
  height: 6.8rem;
  width: 1.2rem;
  background-image: url(~@/assets/ZiggyZig.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0.8rem;
  padding-right: 4rem;
  padding-top: 2rem;
}
.ziggy-box {
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem 1rem 1rem;
  /* border: 1px solid blue; */
}
.ziggy-box .double-click {
  height: 8rem;
  width: 8rem;
  background-image: url(~@/assets/DoubleClick.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(-15deg);
}
.ziggy-box .try-it {
  color: #0044f2;
  background-color: #f5a5f2;
  border-radius: 3px;
  padding: 2px 4px 0 4px;
  font-family: var(--var-IIF-sans-body);
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 1rem;
  font-size: 1.2rem;
  transform: rotate(-5deg) translate(0, -3px);
}
.tooltip-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3rem;
}
.tooltip-controls .button {
  font-family: var(--var-IIF-sans-heavy);
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  background-color: white;
  color: #0044f2;
  padding: 1.5rem 2rem 1.1rem 2rem;
  margin: 0;
}
.tooltip-controls .button:hover {
  color: white;
  background-color: #0044f2;
}

.tooltip-controls .button.back {
  font-family: var(--var-IIF-sans-body);
  background-color: transparent;
  color: white;
  font-weight: normal;
}
.tooltip-controls .button.back:hover {
  text-decoration: underline;
}
.skip-on-boarding {
  margin-top: 2rem;
  font-family: var(--var-IIF-sans-body);
  text-transform: uppercase;
  font-size: 1.1rem;
  text-align: center;
  cursor: pointer;
}
.skip-on-boarding:hover {
  text-decoration: underline;
}

/* Tooltips Typography */
.tooltip-text {
  text-align: center;
}

.tooltip-text .welcome {
  text-transform: uppercase;
  font-size: 1.65rem;
  margin: 0;
}

.tooltip-text h1 {
  text-transform: uppercase;
  color: c1fafb;
  letter-spacing: -0.05rem;
  margin: 0;
}

.tooltip-text h2 {
  font-family: var(--var-IIF-sans-heavy);
  font-weight: normal;
  text-transform: uppercase;
  font-size: 1.7rem;
  margin: 0 0 0.5rem 0;
}

.tooltip-text h3 {
  font-weight: 400;
  font-size: 1.9rem;
  margin: 0rem 0 0.5rem 0;
}
.suggested-tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 1rem 2rem 2rem 2rem;
  margin: 3rem 2rem 2rem 2rem;
  border: 1px solid white;
}
.suggest-tooltip-header {
  background-color: white;
  position: relative;
  top: -2.5rem;
  margin-bottom: -1.5rem;
  padding: 0 0.5rem;
}
.suggest-tooltip-header.lighter {
  background-color: white;
}
.suggested-tooltip-content {
  font-family: var(--var-IIF-sans-body);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.4rem;
}

/* Tooltip #2 Card Illustrations */
.tooltip2-illustrations {
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  padding-top: 4rem;
  width: 80vw;
  border: 1px solid purple;
}
.illustration-card {
  height: 220px;
  width: 150px;
  background-image: url(~@/assets/DummyCard-0.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin: 1rem;
}
.card-1 {
  background-image: url(~@/assets/DummyCard-0.png);
}
.card-2 {
  background-image: url(~@/assets/DummyCard-1.png);
}
.card-3 {
  background-image: url(~@/assets/DummyCard-2.png);
}
#card-4-wrapper {
  background-color: transparent;
  height: 220px;
  width: 150px;
  margin: 1rem;
  /* For 3D effect */
  perspective: 1000px;
}
.card-4-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
#card-4-wrapper.revealed .card-4-inner {
  transform: rotateY(180deg);
}
.card-4-backside,
.card-4-frontside {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
.card-4-backside {
  background-image: url(~@/assets/DummyCard-3.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.card-4-frontside {
  transform: rotateY(180deg);
  background-image: url(~@/assets/DummyCard-3-flipped.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.card-5 {
  background-image: url(~@/assets/DummyCard-4.png);
}
.card-6 {
  background-image: url(~@/assets/DummyCard-5.png);
}
/* Table Area */
.card-table {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 8;
  overflow: scroll;
  box-sizing: border-box;
  padding: 1rem;
  /* background-image: url("../assets/background-stars.png"); */
  background-size: 50%;
  background-repeat: repeat;
}
.card-row {
  display: flex;
  flex-direction: row;
  flex-grow: 8;
}
.card-row.identity-row {
  flex-grow: 2;
}
.card-col {
  display: flex;
  flex-direction: column;
}

/* Sidebar Area */
.sidebar {
  box-sizing: border-box;
  background-color: #e9e9e9;
  width: 20%;
  height: 100%;
  max-height: calc(100vh - 5.5rem);
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  bottom: 0;
  border-left: 4px solid black;
}
.constraints-div {
  height: 100%;
  overflow: scroll;
  box-sizing: border-box;
}
.constraints-div-header {
  color: black;
  font-weight: 900;
  font-size: xx-large;
  padding: 0.8rem 1rem 0.1rem 1rem;
  margin: 0 1rem 0.5rem 1rem;
  /* font-family: var(--var-IIF-sans-heavy); */
  color: #000;
  border-bottom: 1px solid black;
  text-align: center;
  text-transform: uppercase;
}
.identity-div {
  min-height: 6rem;
  overflow: scroll;
  box-sizing: border-box;
  background-color: #e9e9e9;
  border-top: 2px solid #000;
}
.discarded-div {
  min-height: 6rem;
  overflow: scroll;
  box-sizing: border-box;
  border-top: 2px solid black;
}
.discarded-div.disabled > * {
  opacity: 0.35;
}
.discarded-div.disabled .deal-btn .deal-ico.discarded,
.discarded-div.disabled .deal-btn .category-label {
  cursor: not-allowed;
}
.reset-game-div {
  height: 5rem;
  box-sizing: border-box;
  /* background-color: var(--var-IIF-teal); */
  padding: 1rem;
  display: flex;
  flex-direction: column-reverse;
}
.fluidBox {
  display: flex;
  background-color: grey;
  margin-bottom: 20px;
}
.deal-btn {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.deal-ico {
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
  margin: 0.5rem 1rem;
  background-color: var(--var-IIF-teal);
  border-radius: 0.4rem;
  border-top: 1.3rem solid var(--var-IIF-darker-teal);
}
.deal-ico:hover {
  box-shadow: 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.5);
}
.deal-ico:active {
  position: relative;
  top: 0.1rem;
  left: 0.1rem;
  box-shadow: none;
}
.deal-ico.discarded {
  position: relative;
  top: 0.3rem;
  transform: rotate(15deg);
}
.discard-ico {
  height: 4rem;
  margin: 0.5rem 1rem;
}
.category-label {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.8rem;
  text-align: right;
  color: black;
}
.category-label:hover {
  cursor: pointer;
  text-decoration: underline;
}
.category-label:active {
  cursor: pointer;
  text-decoration: underline;
  color: var(--var-IIF-blue);
}

/* Guide Book Btn */
#guide-book-btn {
  border: 3px solid black;
  border-radius: 2rem;
  background-color: white;
  color: black;
  position: fixed;
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  bottom: 2rem;
  left: 2rem;
  text-transform: uppercase;
}
#guide-book-btn:hover {
  background-color: blue;
  color: white;
  transition-duration: 0.6s;
}
#guide-book-btn:active {
  color: var(--var-IIF-yellow);
  transition-duration: 0s;
}

/* Guide Book Pop Up */

.guide-book {
  position: absolute;
  top: 7rem;
  left: 3rem;
  background-color: white;
  border: 2px solid black;
  border-radius: 1rem;
  padding: 0;
  z-index: 2000;
  overflow: hidden;
  box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.3);
}
.guide-book .header {
  cursor: move;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  box-sizing: border-box;
}
.guidebook-move-header {
  cursor: move;
  display: flex;
  height: 100%;
  align-items: center;
}
.move-handle {
  height: 1.2rem;
  margin-right: 0.5rem;
  margin-top: -0.5rem;
}
.guide-book .header h1 {
  /* font-family: var(--var-IIF-sans-heavy); */
  font-size: 1.6rem;
  margin: 0;
  color: white;
}
.guide-book .close-btn {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.2rem;
  background-image: url(~@/assets/close-ico.svg);
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.guide-book .content {
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 5rem 2rem 2rem 2rem;
  text-align: left;
  color: black;
  height: calc(100vh - 10rem);
  width: 40rem;
  resize: both;
  background-color: white;
}

/* Reset All Btn */
#reset-all-btn {
  cursor: pointer;
  font-family: var(--var-IIF-sans-heavy);
  border: 3px solid var(--var-IIF-darker-red);
  margin: -3px;
  border-radius: 2rem;
  background-color: white;
  color: var(--var-IIF-darker-red);
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  text-transform: uppercase;
  text-align: center;
}
#reset-all-btn:hover {
  background-color: var(--var-IIF-darker-red);
  color: white;
  transition: background-color 0.3s;
}
#reset-all-btn:active {
  color: var(--var-IIF-yellow);
  transition-duration: 0s;
}
/* Exit Confirmation Modal */
#modal-screen-dimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2001;
  background-color: rgba(0, 0, 0, 0.5);
}
#exit-confirmation-modal {
  position: absolute;
  width: 50vw;
  top: 25vh;
  left: 25vw;
  margin: 0 auto;
  background-color: white;
  color: black;
  box-sizing: border-box;
  border-radius: 1rem;
  z-index: 2002;
}
#exit-confirmation-modal .modal-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: var(--var-IIF-darker-red);
  color: white;
  box-sizing: border-box;
  padding: 1rem 2rem 0.5rem 2rem;
  border-radius: 1rem 1rem 0 0;
}
.modal-header .exit-modal-title {
  display: flex;
  font-family: var(--var-IIF-sans);
  font-size: 2.2rem;
  text-transform: uppercase;
}
.modal-header .exit-modal-title-ico {
  height: 2rem;
  width: 2rem;
  padding-right: 1.4rem;
  padding-top: 0.2rem;
  background-image: url(~@/assets/IIF-warning-ico.svg);
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 2.2rem;
}
.modal-header .close-btn {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.2rem;
  background-image: url(~@/assets/close-ico.svg);
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.exit-modal-text {
  box-sizing: border-box;
  padding: 4rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.exit-modal-text h1 {
  font-family: var(--var-IIF-sans-heavy);
  font-weight: bold;
  font-size: 2.2rem;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}
.exit-modal-text h2 {
  font-family: var(--var-IIF-sans);
  font-weight: normal;
  font-size: 2.2rem;
  margin: 0;
  padding: 0;
}

#exit-confirmation-modal .btn-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 2rem;
}
#exit-confirmation-modal .btn-container button {
  cursor: pointer;
  background-color: white;
  font-family: var(--var-IIF-sans-heavy);
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  text-transform: uppercase;
  border-radius: 2rem;
  border: 1px solid black;
}
#exit-confirmation-modal .btn-container button.cancel-btn {
  background-color: white;
  color: #333;
  border: 1px solid #333;
}
#exit-confirmation-modal .btn-container button.cancel-btn:hover {
  background-color: #333;
  color: white;
}
#exit-confirmation-modal .btn-container button.confirm-btn {
  background-color: var(--var-IIF-darker-red);
  color: white;
  border: 1px solid white;
}
#exit-confirmation-modal .btn-container button.confirm-btn:hover {
  color: var(--var-IIF-yellow);
  border: 1px solid var(--var-IIF-darker-red);
}
</style>
