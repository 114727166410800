import Airtable from "airtable";

export function getDeck(deckName) {
  return new Promise((resolve, reject) => {
    const API_KEY = process.env.VUE_APP_AIRTABLE_TOKEN;
    Airtable.configure({
      endpointUrl: "https://api.airtable.com",
      apiKey: API_KEY,
    });
    var base = Airtable.base("appn0WDLC3h9ohCW3");
    var ourDeck = {};
    // 1. Setup filter phrase: Returns filter phrase "{Custom Deck} = TRUE()"
    var filterFormula = "{" + deckName + "} = TRUE()";
    // 2. Call the categories table first
    return new Promise((resolve, reject) => {
      var tempDeck = {};
      base("Card Types")
        .select({
          fields: ["Name", "Color 1", "Color 2", "SVG", "SVG Paths"],
          view: "Grid view",
        })
        .eachPage(
          function page(records, fetchNextPage) {
            // This function (`page`) will get called for each page of records.
            records.forEach(function (record) {
              var id = String(record.getId());
              var thisCategory = {};
              thisCategory.id = record.getId();
              thisCategory.name = record.get("Name");
              thisCategory.color1 = record.get("Color 1");
              thisCategory.color2 = record.get("Color 2");
              var svgData = record.get("SVG");
              if (svgData !== undefined) {
                thisCategory.svgURL = svgData[0].url;
              } else {
                thisCategory.svgURL = undefined;
              }
              thisCategory.svgRaw = record.get("SVG Paths");
              thisCategory.cards = [];
              tempDeck[id] = thisCategory;
              //console.log("Category pushed:", ourDeck[id]);
            });

            // To fetch the next page of records, call `fetchNextPage`.
            // If there are more records, `page` will get called again.
            // If there are no more records, `done` will get called.
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              reject(err);
            } else {
              resolve(tempDeck);
            }
          }
        );
    })
      .then((tempDeck) => {
        // 2. update ourDeck with the data from first API call
        ourDeck = tempDeck;
        // 3. Make second API call to get card data
        base("All Cards")
          .select({
            // maxRecords: 100,
            fields: ["Card Type", "Card Text"],
            //Only get records from a specific deck
            filterByFormula: filterFormula,
            view: "Grid view",
          })
          .eachPage(
            function page(records, fetchNextPage) {
              // This function (`page`) will get called for each page of records.
              records.forEach(function (record) {
                var thisCategory = record.get("Card Type")[0];
                var thisCard = {
                  id: String(record.getId()),
                  text: record.get("Card Text"),
                  dealt: false,
                };
                //check if category already exists
                if (ourDeck[thisCategory] !== undefined) {
                  ourDeck[thisCategory].cards.push(thisCard);
                } else {
                  console.log("uh-oh category not found");
                }
              });
              // To fetch the next page of records, call `fetchNextPage`.
              // If there are more records, `page` will get called again.
              // If there are no more records, `done` will get called.
              fetchNextPage();
            },
            function done(err) {
              if (err) {
                reject(err);
              } else {
                // console.log("ourDeck is returned", ourDeck)
                // Scrub empty categories
                let theKeys = Object.keys(ourDeck);
                for (var i = 0; i < theKeys.length; i++) {
                  // console.log(
                  //   ourDeck[theKeys[i]].name,
                  //   ", ",
                  //   ourDeck[theKeys[i]].cards.length
                  // );
                  if (ourDeck[theKeys[i]].cards.length <= 0) {
                    //console.log("Deleting ", ourDeck[theKeys[i]].name);
                    delete ourDeck[theKeys[i]];
                  }
                }
                resolve(ourDeck);
              }
            }
          );
      })
      .catch((err) => {
        console.error("Fetching card categories from AirTable failed: ", err);
      });
  });
}
